<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.diagnostic.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <v-flex v-t="'sa.support.actions.affect-theme-to-users.accounting-firm'" class="title" mb-3 />
                            <v-autocomplete
                                v-model="selectedAccountingFirmId"
                                color="primary"
                                :items="accountingFirms"
                                :label="$t('accounting_firm')"
                                :loading="loadingAccountingFirmsList"
                                @change="getVendors(selectedAccountingFirmId)"
                            />
                            <v-flex v-t="'sa.support.actions.select-vendor'" class="title" mb-3 />
                            <v-autocomplete
                                v-model="selectedVendorId"
                                :disabled="!selectedAccountingFirmId"
                                color="primary"
                                :items="vendors"
                                :label="$t('vendors.label')"
                                :loading="loadingVendorsList"
                            />
                            </v-layout>
                    </v-flex>
                    <v-flex my-3 shrink>
                        <WButton :disabled="!selectedVendorId || !selectedAccountingFirmId" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
    // eslint-disable-next-line
    name: 'DiagnosticGed',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
            accountingFirms: new Array(),
            vendors: new Array(),
            selectedAccountingFirmId: null,
            selectedVendorId: null,
            loadingAccountingFirmsList: false,
            loadingVendorsList: false,
        }
    },
    created: function () {
		this.getAccountingFirms()
	},
    methods: {
        getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
                    return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id,
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
        getVendors: function (accountingFirmId) {
            this.loadingVendorsList = true
            this.service
				.getAccountingFirm(accountingFirmId)
                .then(accountingFirm => {
                    return accountingFirm.vendors.map(vendor => {
                        return {
                            text: vendor.company,
                            value: vendor.id,
                        }
                    })
                })
                .then(vendors => {
                    this.vendors = vendors
                })
                .finally(() => {
                    this.loadingVendorsList = false
                })
		},
        execute: function () {
            return this.service.executeCommand({
                    command: "support:diagnostic:ged",
                    params: {
                        'vendor-id': this.selectedVendorId,
                    }
            }).then(result => {
                if (result.data.data.exitCode !== 0) {
                    this.appEventBus.emit(this.appEvents.SNACKBAR_WARNING, result.data.data.output)
                    return
                }
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, result.data.data.output)
            }).catch(error => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
            })
        }
    }
}
</script>